<template>
    <div class="audit">
      <rxNavBar v-if="androidFlg == false" title="物品变更列表"></rxNavBar>
      <rxNavBar v-else title="物品变更列表" androidOrIOSFlag="true"></rxNavBar>
      <div class="head">
        <div class="search">
          <div class="search-icon" @click="goSearch"></div>
          <input type="text" placeholder="物品规格" v-model="inputValue" @keyup.enter="goSearch">
        </div>
        <div class="types">
          <div class="type" @click="approveStatusShow = !approveStatusShow" id="downList">{{ goodsallocationapprovalstatusList[number] ? goodsallocationapprovalstatusList[number].dictionaryTitle : '待审批'}}</div>
          <div :class="approveStatusShow?'small-icon-rotate':'small-icon'"></div>
          <van-popup v-model="approveStatusShow" position="bottom">
            <van-picker show-toolbar :columns="goodsallocationapprovalstatusList" @cancel="approveStatusShow = false" :default-index="number" value-key="dictionaryTitle" @confirm="changeStatus"/>
          </van-popup>
        </div>
      </div>
      <!--列表list-->
      <div class="auditDiv">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" >
          <van-list v-model="loading" :finished="finished" :finished-text="isEmptyFlag ? '' : '已经到底了' " @load="this.onLoad">
            <div class="stateList" v-for="(item,index) in orders"   :key="index" >
              <div>
                <!--                循环列表状态 -->
                <div v-if="item.approvalStatus==0||item.approvalStatus==3||item.approvalStatus==4||item.approvalStatus==5" class="stateUnapproval">
                  {{item.approvalStatusStr}}
                </div>
                <div v-if="item.approvalStatus==1" class="statePassed">
                  {{item.approvalStatusStr}}
                </div>
                <div v-if="item.approvalStatus==2" class="stateRejected">
                  {{item.approvalStatusStr}}
                </div>
                <!--                循环列表头-->
                <div class="headInfo" >
                  <span class="headSpan">{{item.addOrUpdate}}</span>
                  <div class="dot"></div>
                  {{item.itemName}}
                </div>
                <!--                循环列表下部分-->
                <div class="listFloor">
                  <div class="createDiv">
                    <span  class="createMethodsDiv">变更原因 : </span>
                    <span class="createTimeDiv">{{item.modifyReason}}</span>
                    <!--审核按钮-->
                  </div>
                </div>
                <div class="btnArray">
                  <button class="btn1"   @click="approve(item)" v-if="checkApproveAuth(item)">审核</button>
                  <button class="btn2" v-else   @click="detailDrawer(item)" >查看</button>
                </div>
              </div>
            </div>
          </van-list>
          <common-empty v-if="isEmptyFlag"></common-empty>
        </van-pull-refresh>
      </div>
    </div>
</template>

<script>
import {checkAndroid, checkAuthList, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {queryBaseData, getStaffFuntionModelList, queryGoodsAllocationApprovalList} from "../../../getData/getData";
import {Button, Divider, DropdownItem, DropdownMenu, List, NavBar, Picker, Popup, PullRefresh, Search} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
export default {
  name: "goodsChangeApplication",
  components: {
    [Button.name]:Button,
    [Search.name]:Search,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Divider.name]:Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh
  },
  data(){
    return{
      androidFlg: '',
      inputValue:'',
      goodsallocationapprovalstatusList:[
        {
          dictionaryValue:'0',
          dictionaryTitle:'待审批',
        },
        {
          dictionaryValue:'1',
          dictionaryTitle:'已审批',
        },
        {
          dictionaryValue:'2',
          dictionaryTitle:'全部',
        },
      ],
      approveStatusShow:false,  //审批状态选择
      number:0,
      authButtonsList:[],  //权限
      flag:false,
      orders:[],
      page:1,
      finished:false,
      approveStatusStr:'',
      loading:true,
      isLoading : false,
      //判断列表是否为空，是否显示空状态图
      isEmptyFlag:false,
    }
  },
  created() {
    this.getStaffFuntionModelList()
    // 记录用户的下拉选择，用于查看详情之后返回的初始化
    let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
    console.log(localStorage.getItem('currentLabel'))
    if(currentLabel != null){
      if(currentLabel != '' ){
        this.number = Number(currentLabel);
      }
    }else{
      this.number =0
    }
    if(JSON.parse(localStorage.getItem('inputValue'))){
      this.inputValue = JSON.parse(localStorage.getItem('inputValue'))
    }
    this.onLoad()

  },
  mounted() {
    this.checkPhoneorMobel()
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if(from.name == null){
      localStorage.removeItem('currentLabel');
      localStorage.removeItem('approveStatusStr');
      localStorage.removeItem('inputValue');
    }
    next()
  },
  methods:{
    checkAuthList,
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      } else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    changeStatus(value,index){
      //将选择的类型放入本地内存中，
      if(index == ''){
        index = 0;
      }
      localStorage.setItem('currentLabel',JSON.stringify(index))
      this.flag=true
      this.orders = [];
      this.page = 1;
      this.finished = false;
      this.approveStatusShow = false;
      this.number= index;
      this.approveStatusStr = this.goodsallocationapprovalstatusList[index].dictionaryTitle;
      this.loading = true;
      this.onLoad();
    },
    goSearch(){
      this.orders=[]
      this.page = 1;
      if(this.inputValue!=''){
        localStorage.setItem('inputValue',JSON.stringify(this.inputValue))
      }else{
        localStorage.removeItem('inputValue')
      }
      this.finished = false;
      this.loading = true;
      this.onLoad()
    },
    onRefresh() {
      this.orders = []
      this.finished = false;
      this.page = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.isLoading = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    onLoad(){
      var that = this
      let initData = {}
      console.log()
      //下拉刷新的页数
      initData.currentPage = that.page+''
      initData.numberPage = 10
      initData.itemName = that.inputValue
      initData.staff_id = getStaffId()
      // initData.approvalStatus = that.number==this.goodsallocationapprovalstatusList.length-1 ? '':that.number
      initData.appApprovalStatus = that.number
      queryGoodsAllocationApprovalList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.count =  response.data.data.pageCount
          let list = response.data.data.data==undefined?[]:response.data.data.data
          for(let i=0;i<list.length;i++){
            that.orders.push(list[i])
          }

          // 加载状态结束
          that.loading = false;
          that.page++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
            //console.log(that.finished)
          }
          if(that.orders.length == 0){
            that.isEmptyFlag = true;
          }else{
            that.isEmptyFlag = false;
          }
        })
      })
    },
    checkApproveAuth(item){
      //上级审批
      (item.approvalStatus==0 && item.superiorFlag==1||item.approvalStatus==3||item.approvalStatus==4||item.approvalStatus==5) && checkAuthList(this.authButtonsList,'approval')
      if(item.approvalStatus==0&&item.superiorFlag==1&&checkAuthList(this.authButtonsList,'superiorApproval')){
          return true
      }
      //财务审批
      if(item.approvalStatus==3&&checkAuthList(this.authButtonsList,'financeApproval')){
        return true
      }
      //城市总
      if(item.approvalStatus==4 && item.cityFlag ==1 &&checkAuthList(this.authButtonsList,'cityApproval')){
        return true
      }
      //总经理
      if(item.approvalStatus==5&&checkAuthList(this.authButtonsList,'GMApproval')){
        return true
      }
      return false
    },
    //审批
    approve(item){
      this.$router.push({
        name:'goodsChangeApprove',
        query:{
          id:item.id,
          approval_id:item.approval_id,
          enterType:'approve'
        }
      });
    },
    //详情
    detailDrawer(item){
      this.$router.push({
        name:'goodsChangeApprove',
        query:{
          id:item.id,
          approval_id:item.approval_id,
          enterType:'detail'
        }
      });
    },
    queryBaseData(){
      let that=this
      let queryData={}
      queryData.fdName = ['GOODSALLOCATIONAPPROVALSTATUSMAP']
      queryBaseData(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.goodsallocationapprovalstatusList = response.data.data.GOODSALLOCATIONAPPROVALSTATUSMAP
          that.goodsallocationapprovalstatusList.push({dictionaryValue:'',dictionaryTitle:'全部'})
        })
      })
    },
    getStaffFuntionModelList () {
      let that = this
      let data = {}
      data.staff_id = getStaffId()
      data.menuName = 'prepareAllocationApproval_index'
      getStaffFuntionModelList(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.authButtonsList = response.data.data.data
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
  //搜索
  .head{
    width: 100%;
    position: fixed;
    z-index: 8;
    overflow: hidden;
    background: #f8f8f8;
  }
  //搜索输入
  .search{
    margin: 8px 20px 0 20px;
    height: 36px;
    border-radius: 30px;
    background-color: rgba(244, 244, 244, 1);
    display: flex;
    align-items: center;
  }
  .search-icon{
    width: 19px;
    height: 19px;
    margin-left: 9px;
    background-image: url("../../../assets/images/search-icon.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  input{
    font-size: 14px;
    margin-left: 10px;
    background-color: rgba(244, 244, 244, 1);
    border: 0;
    width: 250px;
  }
  input::-webkit-input-placeholder{
    color: rgba(199, 199, 199, 1);
  }
  //搜索选择
  .types{
    width: 100%;
    height: 30px;
    display: flex;
  }
  .type{
    width:auto;
    height: 17px;
    line-height: 17px;
    text-align: left;
    color: #fe5e3a;
    margin: 15px 0 0 15px;
    color: rgba(255, 93, 59, 1);
    font-size: 13px;
    font-family: PingFangSC-Regular;
  }
  .small-icon{
    width: 8px;
    height: 8px;
    margin: 19px 0 0 5px;
    background-image: url("../../../assets/images/small-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
  }
  .small-icon-rotate{
    width: 8px;
    height: 8px;
    margin: 18px 0 0 5px;
    background-image: url("../../../assets/images/small-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    transform:rotate(180deg);
  }
  .choose-select{
    position: fixed;
    z-index: 6;
    padding-top: 90px;
    width: 100%;
  }
  .selectList{
    width: 100%;
    height: auto;
    padding: 5px 0 14px 0;
    background-color: white;
  }
  .select{
    width: 100%;
    height: 35px;
    line-height: 30px;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
    color: #999999;
  }
  .selectActive{
    color: #fe5e3a;
  }
  .h-line{
    height: 1px;
    margin: 0 5px 0 10px;
    background-color: white;
  }

  //列表
  .auditDiv{
    padding-top: 80px;
  }
  /*    循环列表DIV*/
  .stateList{
    border-radius: 0.3rem;
    width: 93%;
    margin-left: 0.35rem;
    position: relative;
    margin-bottom: 0.4rem;
    background-color: #ffffff;
    padding-bottom: 0.5rem;
  }
  .stateUnapproval,.statePassed,.stateRejected{
    height: 0.45rem;
    width: fit-content;
    padding: 0 0.13rem;
    border-radius: 0.2rem 0rem;
    font-size: 11px;
    text-align: center;
    color: #ffffff;
    line-height: 0.5rem;
  }
  /*列表状态样式 1是未审批*/
  .stateUnapproval{
    background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  }
  /*列表状态样式 2是已通过*/
  .statePassed{
    background: -webkit-linear-gradient(left,#66CB94,#28D3B0);
  }
  /*列表状态样式 3是已驳回*/
  .stateRejected{
    background: -webkit-linear-gradient(left,#E02020,#FF431B);
  }
  /*列表头部红色重点信息*/
  .headSpan{
    float: left;
    margin-right: 0.1rem;
    color: #FF5D3B;
  }
  /*列表头部小圆点*/
  .dot{
    border-radius: 50%;
    width: 0.01rem;
    height: 0.01rem;
    border: 0.05rem solid black;
    float: left;
    margin-top: 0.24rem;
    margin-right: 0.1rem;
  }
  /*列表头部信息*/
  .headInfo{
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    font-size: 15px;
    font-weight: bold;
  }
  /*列表详情*/
  .listDetail{
    position: relative;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .listDetail .title{
    display: flex;
    justify-content: space-around;
  }
  .listDetail .titleData{
    display: flex;
    justify-content: space-around;
  }
  .listDetail:after{
    content: "";
    display: block;
    clear: both;
  }
  /*    申请人信息DIV*/
  .proposerDiv{
    //margin-left: 0.5rem;
    font-size: 13px;
    color: #999999;
    width: 2rem;
    text-align: center;
    //float: left;
  }
  /*分割线1*/
  .rule1{
    position: absolute;
    //left: 2.3rem;
    //top: 0.1rem;
    border: 0.01rem solid rgb(235, 235, 235);
    height: 1rem;
    //float: left;
  }
  /*分割线2*/
  .rule2{
    position: absolute;
    left: 5.3rem;
    top: 0.1rem;
    border: 0.01rem solid #f7f7f7;
    height: 1rem;
    width: 0.01rem;
    float: left;
  }
  /*装修预算和改造户型*/
  .budgetDiv,.remouldDiv{
    font-size: 13px;
    color: #999999;
    //margin-left: 1.68rem;
    //float: left;
  }
  /*   装修预算 */
  .budgetDiv{
    width: 2rem;
    text-align: center;
  }
  /*    分割线2*/
  /* 申请人取值DIV*/
  .proposerValueDiv{
    margin-top: 0.3rem;
    font-size: 14px;
    color: #FF5D3B;
    height: 0.5rem;
    //float: left;
    /*margin-left: 0.6rem;*/
    width: 2rem;
    /*border: 1px solid red;*/
    text-align: center;
  }
  /*装修预算和改造户型取值*/
  .budgetValueDiv,.remouldValueDiv{
    font-size: 14px;
    color: #FF5D3B;
    margin-top: 0.3rem;
    //float: left;
    width: 2rem;
    /*border: 1px solid red;*/
    text-align: center;
  }
  .budgetValueDiv{
    //margin-left: 0.8rem;
  }
  .remouldValueDiv{
    margin-left: 1.3rem;
  }
  /*    分割线3*/
  .rule3{
    border: 0.01rem solid #efefef;
    width: 100%;
    height: 0rem;
    margin-top: 0.1rem;
  }
  /*    申请原因*/
  .apply-result{
    margin-left: 0.5rem;
    margin-top: 0.3rem;
    line-height: 0.6rem;
    margin-bottom: 0.3rem;
    padding-right: 10px;
  }
  /*    尾部DIV*/
  .listFloor{
    box-sizing: border-box;
    padding: 0.3rem 0.5rem;
    //margin-left: 0.5rem;
    //margin-top: 0.35rem;
    max-height: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    //line-height: 0.73rem;
    display: flex;
    .createDiv{
      width: 70100%;
    }
  }


  /*    尾部 创建方式的createMethodsDiv*/
  .createMethodsDiv{
    font-size:13px ;
    color: #999999;
  }
  /*    尾部 创建时间的createTimeDiv*/
  .createTimeDiv{
    display: inline;
    font-size:13px ;
    margin-left: 0.2rem;
    color: #999999;
    max-height: 1.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*    下部按钮*/
  .btnArray{
    display: flex;
    justify-content: right;
  }
  .btn1,.btn2{
    /*float: right;*/
    margin-top: 0.2rem;
    margin-right: 0.7rem;
    height: 0.82rem;
    width: 2.3rem;
    border-radius: 0.2rem;
    border: 0rem solid transparent;
    box-shadow: none;
    line-height: 0.7rem;
    font-size: 13px;
  }
  /*按钮*/
  .btn1{
    background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
    color: white;
  }
  .btn2{
    padding: 0.01rem;
    border: 0.03rem solid transparent;
    background-image: linear-gradient(white,white),
    linear-gradient(to right ,#FFC274,#FF5D3B);
    background-clip: padding-box,border-box;
    background-origin: border-box;
    color: #FF5D3B;
  }
</style>